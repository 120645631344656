.card {
  background: white;
  text-align: center;
  padding: 25px;
  position: absolute;
  top: 40%;
  left: 50%;
  right: 50%;
  width: 40%;
  -webkit-transform: translate(-50%, -50%);
}

.card h1,
.card h2,
.card h4,
.card p,
.card small {
  font-family: "Open Sans";
}

.card h1,
.card h4 {
  color: #cc0000;
}

.card h4 {
  font-weight: 200;
}

.card h2 {
  font-weight: 600;
}

@media (max-width: 700px) {
  .card {
    width: 100%;
  }
  .card h1 {
    font-size: 1.2em;
  }
  .card h2 {
    font-size: 1em;
  }
}

.list-participants {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
}

.list-participants:hover {
  background-color: #efefef;
}

input::-ms-reveal {
  display: none;
}

/* Contenedor responsive */
.responsive-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

/* Estilo de texto principal */
.text-main {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

/* Estilo de texto secundario */
.text-secondary {
  font-size: 0.9rem;
  color: #666;
}

/* Línea divisora */
.divider {
  margin: 1rem 0;
  border: none;
  border-top: 1px solid #ccc;
}

/* Media queries para dispositivos pequeños */
@media (max-width: 480px) {
  .text-main {
    font-size: 0.9rem;
  }

  .text-secondary {
    font-size: 0.8rem;
  }

  .responsive-container {
    padding: 0.5rem;
  }
}