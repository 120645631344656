@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: "Open Sans" !important;
}

button {
  background-color: #c00000;
  color: white;
  border: 0;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
}

footer {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

footer > small {
  color: #6c757d;
  font-size: 80%;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
